import React from 'react';

const OutsourcingAlwaysReady = ({ className }: { className?: string }) => (
  <svg
    className={className}
    width="48"
    height="48"
    viewBox="0 0 48 48"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <path
      d="M29.8495 17.3665C28.8446 17.3717 27.861 17.0793 27.0232 16.5266C26.1855 15.9739 25.5314 15.1858 25.1444 14.2623C24.7574 13.3387 24.6548 12.3214 24.8495 11.3397C25.0443 10.3579 25.5279 9.45577 26.2385 8.74814C26.9491 8.04052 27.8547 7.55921 28.8405 7.36526C29.8264 7.17131 30.8479 7.2735 31.7753 7.65887C32.7027 8.04425 33.4941 8.69536 34.0491 9.5296C34.6041 10.3639 34.8977 11.3437 34.8925 12.3444C34.8855 13.6742 34.3518 14.9475 33.4076 15.8878C32.4634 16.8281 31.1848 17.3595 29.8495 17.3665ZM29.8495 8.61747C29.1082 8.61223 28.3822 8.82645 27.7633 9.23277C27.1443 9.63908 26.6603 10.2194 26.373 10.8999C26.0857 11.5804 26.008 12.3305 26.1494 13.0552C26.2908 13.7799 26.6451 14.4463 27.1674 14.9702C27.6897 15.494 28.3565 15.8515 29.0832 15.9975C29.8099 16.1434 30.5634 16.0713 31.2488 15.79C31.9342 15.5087 32.5202 15.0312 32.9326 14.4178C33.345 13.8043 33.5654 13.0826 33.5654 12.3444C33.5654 11.3606 33.1748 10.4165 32.4787 9.71836C31.7826 9.02019 30.8374 8.62445 29.8495 8.61747Z"
      fill="#FCFCFD"
    />
    <path
      d="M28.4158 48.0002H26.5845C26.4086 48.0002 26.2398 47.9305 26.1154 47.8066C25.9909 47.6826 25.921 47.5146 25.921 47.3394V33.6741H23.5853C23.4181 33.6747 23.257 33.6126 23.134 33.4999C23.0109 33.3872 22.9351 33.2322 22.9217 33.0662L21.6744 20.247C21.663 20.1092 21.6953 19.9712 21.7667 19.8527C21.8381 19.7342 21.9451 19.641 22.0725 19.5862L24.5673 18.5289C24.6449 18.4902 24.7298 18.4687 24.8165 18.4653C24.9032 18.462 24.9898 18.477 25.0702 18.5095C25.1505 18.542 25.2229 18.5911 25.2827 18.6537C25.3425 18.7163 25.3883 18.7909 25.4168 18.8725C25.452 18.9523 25.4709 19.0383 25.4726 19.1254C25.4742 19.2126 25.4583 19.299 25.4262 19.38C25.3942 19.4611 25.3465 19.5352 25.2856 19.5978C25.2247 19.6604 25.1521 19.7105 25.0718 19.7449L23.028 20.6171L24.1691 32.3525H26.5845C26.7605 32.3525 26.9292 32.4222 27.0537 32.5461C27.1781 32.67 27.2481 32.8381 27.2481 33.0133V46.6786H28.3895C28.5655 46.6786 28.7342 46.7482 28.8587 46.8722C28.9831 46.9961 29.0531 47.1641 29.0531 47.3394C29.0532 47.5101 28.9868 47.6743 28.8681 47.7975C28.7493 47.9207 28.5871 47.9933 28.4158 48.0002Z"
      fill="#FCFCFD"
    />
    <path
      d="M33.0874 47.9996H31.2827C31.1067 47.9996 30.938 47.9299 30.8135 47.806C30.6891 47.682 30.6191 47.514 30.6191 47.3388C30.6191 47.1635 30.6891 46.9955 30.8135 46.8716C30.938 46.7477 31.1067 46.678 31.2827 46.678H32.4238V33.0127C32.4238 32.8375 32.4938 32.6695 32.6182 32.5455C32.7426 32.4216 32.9114 32.3519 33.0874 32.3519H35.5028L36.6176 20.6165L34.5739 19.7443C34.4203 19.6649 34.3006 19.5327 34.2372 19.3723C34.1738 19.212 34.1709 19.0343 34.2288 18.8719C34.2634 18.7919 34.3137 18.7196 34.3765 18.6589C34.4394 18.5983 34.5135 18.5505 34.5949 18.5186C34.6763 18.4867 34.7634 18.4711 34.8509 18.4728C34.9383 18.4744 35.0244 18.4933 35.1046 18.5283L37.5997 19.5856C37.7271 19.6404 37.8341 19.7336 37.9055 19.8521C37.9769 19.9706 38.0092 20.1086 37.9979 20.2464L36.7501 33.0656C36.7368 33.2316 36.6613 33.3866 36.5382 33.4993C36.4152 33.612 36.2538 33.6741 36.0866 33.6735H33.7509V47.3388C33.7509 47.514 33.6813 47.682 33.5568 47.806C33.4324 47.9299 33.2633 47.9996 33.0874 47.9996Z"
      fill="#FCFCFD"
    />
    <path
      d="M14.8006 29.4978C12.245 29.4961 9.73315 28.8358 7.50965 27.5812C5.28616 26.3267 3.4266 24.5206 2.11153 22.3385C0.796468 20.1563 0.0706598 17.6726 0.00490322 15.1285C-0.0608533 12.5844 0.535747 10.0667 1.73635 7.82014C2.93694 5.57353 4.70073 3.67441 6.85648 2.30764C9.01222 0.940873 11.4864 0.152684 14.0385 0.0200433C16.5906 -0.112598 19.1336 0.414833 21.4205 1.5507C23.7074 2.68657 25.6602 4.39249 27.0891 6.50243C27.1381 6.57295 27.1724 6.65264 27.1896 6.73668C27.2068 6.82071 27.2066 6.90725 27.1893 6.99125C27.1719 7.07525 27.1376 7.15474 27.0885 7.22517C27.0394 7.2956 26.9765 7.35551 26.9035 7.40101C26.8343 7.45105 26.7558 7.48673 26.6725 7.50588C26.5892 7.52502 26.5027 7.52713 26.4185 7.51233C26.3343 7.49753 26.254 7.46612 26.1823 7.41973C26.1106 7.37334 26.049 7.31287 26.0012 7.24227C24.5628 5.11172 22.5423 3.4356 20.1783 2.41153C17.8143 1.38747 15.2057 1.05825 12.6599 1.46294C10.1142 1.86763 7.73762 2.98917 5.81126 4.69526C3.8849 6.40135 2.48895 8.62091 1.78818 11.0915C1.08742 13.5621 1.11119 16.1807 1.85655 18.6383C2.60191 21.0959 4.03772 23.2897 5.99464 24.961C7.95156 26.6322 10.348 27.7111 12.9006 28.0701C15.4533 28.429 18.0558 28.0532 20.4009 26.9869C20.4805 26.9379 20.5698 26.9062 20.6627 26.894C20.7555 26.8818 20.8499 26.8892 20.9397 26.9159C21.0294 26.9426 21.1124 26.9879 21.1833 27.0489C21.2542 27.1098 21.3112 27.185 21.3508 27.2696C21.3904 27.3541 21.4117 27.446 21.413 27.5393C21.4144 27.6326 21.396 27.725 21.3589 27.8106C21.3218 27.8963 21.267 27.9732 21.1979 28.0362C21.1288 28.0992 21.0471 28.1469 20.9581 28.1762C19.0282 29.0627 16.9258 29.5139 14.8006 29.4978Z"
      fill="#00E0FF"
    />
    <path
      d="M19.206 19.7982C19.1161 19.797 19.0276 19.7778 18.9455 19.7414C18.8634 19.7051 18.7895 19.6525 18.7284 19.5869L14.3224 15.2256C14.2077 15.0928 14.1422 14.9251 14.1367 14.75V7.03188C14.1367 6.85663 14.2064 6.68861 14.3308 6.56468C14.4552 6.44076 14.6243 6.37109 14.8003 6.37109C14.9762 6.37109 15.145 6.44076 15.2694 6.56468C15.3939 6.68861 15.4638 6.85663 15.4638 7.03188V14.4329L19.6573 18.6089C19.7208 18.6683 19.7714 18.7397 19.806 18.8193C19.8406 18.8989 19.8585 18.9849 19.8585 19.0716C19.8585 19.1583 19.8406 19.2441 19.806 19.3236C19.7714 19.4032 19.7208 19.4747 19.6573 19.534C19.604 19.6062 19.5366 19.667 19.459 19.7124C19.3815 19.7578 19.2953 19.7869 19.206 19.7982Z"
      fill="#00E0FF"
    />
  </svg>
);

export default OutsourcingAlwaysReady;
